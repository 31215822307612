<template>
  <div v-if="show"
       class="thematic-slider">
    <div class="thematic-slider__wrapper">
      <Column class="thematic-slider__limiter">
        <Row align="center"
             class="thematic-slider__title-holder"
             justify="between">
          <slot name="thematic-slider-title"/>
          <slot name="thematic-slider-button"/>
        </Row>
        <slot name="thematic-slider-filter"/>
        <slot name="thematic-slider-cloud-tags"/>
      </Column>
      <!--      <Loader v-if="!array || array.length === 0"-->
      <!--              :params="{style}"/>-->
      <Slider :items="array"
              :indentation="indentation"
              :count="params.count"
              :component="params.component"
              :height="params.height"
              limiter/>
      <!--              v-else/>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThematicSlider',
  data() {
    return {
      style: {
        width: '100%',
        maxWidth: '1110px',
        height: '405px',
        margin: '0 auto',
      },
    };
  },
  props: {
    params: {},
  },
  computed: {
    show() {
      return this?.array?.length > 0;
    },
    array() {
      return this?.params?.array;
    },
    indentation() {
      let indentation;
      if (this.$laptop) indentation = 30;
      if (this.$tablet) indentation = 30;
      if (this.$mobile) indentation = 8;
      return indentation;
    },
  },
};
</script>
